import React from 'react';
import './navbar.css';
import Logo from '../../assets/logo.png'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
const PortfolioNav = () => {
  return (
    <Navbar expand="lg" className="navbar_main">
        <Navbar.Brand href="/" className="navbar_logo">
          <img src={Logo} className="logo"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"  />
        <Navbar.Collapse>
          <Nav>
            <Nav.Link href="/" className="navbar_text">Home</Nav.Link>
            <Nav.Link href="/games" className="navbar_text">Games</Nav.Link>
            <Nav.Link href="/novels" className="navbar_text">Novels</Nav.Link>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  )
}

export default PortfolioNav