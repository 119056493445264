

import './game_pages.css';
import Button from 'react-bootstrap/Button';
import wo_1 from '../../assets/images/whorigami/wo_1.png';
import wo_2 from '../../assets/images/whorigami/wo_2.png';
import wo_3 from '../../assets/images/whorigami/wo_3.png';
import wo_4 from '../../assets/images/whorigami/wo_4.png';


function Whorigami() {
  return (
    <body>

        <div className="main_content">
            <div className="page_content">
                <table>
                    <tr>
                        <td className="table_main">
                            <h1> Whorigami </h1>
                            <p> In Whorigami, the player controls a sentient piece of paper on its journey on finding out who it is, and what its purpose in life is.
                                Starting clean and smooth, the piece of paper continues changing from what it was before its journey began, making its way through many
                                tough ordeals that leave permanent marks on its body. This game was created as part of a university project at the Cologne Game Lab, where we
                                were prompted to create a narrative game with the theme 'A Blank Page'.
                            </p>
                            <h2> Gameplay </h2>
                            <p> The player takes control of the paper and moves it through the different sections of the game. Each section has different gameplay mechanics
                                and controls that fit to its current form. For example, allowing it to roll simply forward or backward while it is a piece of paper, or
                                flying through the air while supported by the wind underneath it when taking the form of an origami bird.
                            </p>
                            <table className="table_info">
                                        <tr>
                                            <td>Position</td>
                                            <td>Lead Programmer / Narrator </td>
                                        </tr>
                                        <tr>
                                            <td>Game Engine</td>
                                            <td>Unity 2020.3.11f1</td>
                                        </tr>
                                        <tr>
                                            <td>Context</td>
                                            <td>2nd Semester Project @ CGL</td>
                                        </tr>
                                        <tr>
                                            <td>Creation Period</td>
                                            <td>June 2021 - July 2021</td>
                                        </tr>
                                    </table>
                        </td> 
                    </tr>
                </table>  
                
            </div>
            <div className="page_content_2">
                <table className="content_table">
                    <tr >
                        <td>
                            <iframe className="video_embed" src="https://www.youtube.com/embed/RX9JyABLaOg" title="Whorigami Gameplay Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>    
                        </td>
                        <td>
                            <table>
                                <tr>
                                    <td><img src={wo_1} className="galeryImage"/></td>
                                    <td><img src={wo_2} className="galeryImage"/></td>
                                </tr>
                                <tr>
                                    <td><img src={wo_3} className="galeryImage"/></td>
                                    <td><img src={wo_4} className="galeryImage"/></td>
                                </tr>
                            </table>
                        </td>
                    </tr>                    
                </table>
            </div>
        </div>
    </body>

  );
}

export default Whorigami;