import './writingSamples.css';

function WSA() {
    return (
        <body>
            <div className="sampleBlock">
                <p  className="textBlock">
                <br /><h1>Blue Mage</h1>
                <br />The air was stuffy, and with every single breath, Avio seemed to breathe in a fistful of dust. He instinctively held his hand in front of his mouth when he heard the floorboards creaking behind him, “When you’re done here, come back down. They prepared some food as well.”
                <br />Ah... thank you,” Avio replied, watching as the lodgings’ owner closed the door to this room. Slowly, Avio took a look around at the room, approaching the narrow bed just a few steps in front of him. It was cramped in here as if it was just a reused closet, but it was probably enough. There was even a desk, though he doubted he would need it. Alexander had only paid for a single night, after all. Tomorrow, they were supposed to either find a guild to join or pay for their stay at this place themselves, though most would probably go for the former option.
                <br />Avio dropped down onto the bed, nearly breaking his tailbone in the process. For some reason, he thought it would be soft and bouncy, but it was pretty hard. Even so, he was incredibly exhausted and felt like he could sleep for three days straight; his stomach was the only thing that was stopping him from doing exactly that. It was like he had swallowed a few needles that were now jumping around inside of him.
                <br />Forcing himself to get back up, Avio approached the door and pulled it open before finding himself taken aback by the two figures blocking his sight. It was Lionel and Thane, seemingly talking about something without realizing that they were standing right in front of one of the rooms.
                <br />Hoping that they would notice him without him having to do anything, Avio stood there for a few moments, overhearing part of their conversation, “So, will you join me, then?”
                <br />“Is there a reason why you want me in particular?”
                <br />“I just have a good feeling about you, that’s all,” Lionel said, and even though Avio wasn’t able to see his face, he could practically just hear that he was grinning like he did all day so far.
                <br />It seemed as though the conversation was going to continue further than this, and Avio wasn’t really the type of person that enjoyed spying on people, so he cleared his voice, “Can’t you have this conversation in front of your own rooms?” 
                <br />Thane turned toward Avio immediately, showing a clearly apologetic expression, “Ah, sorry about that... Aaron?”
                <br />“Avio, and don’t worry about it. I’d just need to get through here for now,” he replied with a sigh, and the two people in front of him quickly made way. He closed the door behind himself and made his way to the stairs. While starting to walk down, he locked eyes with Lionel for a moment, feeling a shiver run down his spine. He wondered whether or not there really was something behind the whole ‘talent’ thing. Lionel didn’t even flinch when Avio spoke up, and having that sort of self-control was a talent in and of itself.
                <br />Soon, he found himself in the lodgings’ courtyard, where not only the twelve from the current ‘batch’ but a good number of people from Alexander’s troupe were gathered, excluding the man himself.
                <br />He walked over to the place where a few of the others were lining up already, in front of a large metal pot. Figuring that this was the food that was mentioned, he quickly found himself at the line’s end.
                <br />He was given a bowl filled with some sort of cloudy beige soup with chunks of vegetables in it, together with a piece of wood that was harder than the bowl he was holding, “If you want any more, just come by again,” he was told as he left to find a seat.
                <br />Avio quickly started to eat, burning his lip in the process, “Ah, fuck...” he let out, right when someone sat down next to him, “Is it that bad?” they asked, and Avio looked at them with tired eyes for a few moments.
                <br />“It’s Rayne,” she told him with a smile, “Avio, right?”
                <br />After thinking about it for a few moments as he looked at the bowl in front of him, Avio shrugged, “It’s kinda bad, yeah. Super bland, the vegetables are either overcooked or undercooked, and the bread is harder than my teeth,” he pointed out, even if that wasn’t the reason for his exclamation. A bit taken aback by the straightforward answer, Rayne awkwardly laughed, “Is that so?”
                <br />Rayne also started to eat, quickly understanding what Avio meant, even if she didn’t seem as concerned with it, “Do you have any plans yet?” she finally asked after a few moments, and Avio quickly denied, “Not really. I guess I’ll find a guild and then figure out what to do about money. Apparently, I was only given enough for the fee to join a guild plus the initial training.”
                <br />“Wait, seriously? Nothing for after that?”
                <br />“Nope. It looks like all of you got a lot more than that,” Avio sighed, annoyed, as Rayne awkwardly looked at the pouch she was trying to keep safe on her lap. Before she could figure out what to say, one of the members of Alexander’s militia that was sitting at the table with them spoke up, “I mean, you’re pretty lucky that you got anything at all, all things considered. Usually, giving anything at all to the last of the batch is a waste of money.”
                <br />Avio looked up from his food and locked eyes with the man sitting across from him, “What’s that supposed to mean?”
                <br />“Well, you heard it earlier, didn’t you? The order defines the talent, so you have the worst talent of the dozen of you. If you have any sort of conscience, you should give back what was given to you,” he pointed out with a sort of sadistic grin. He held it for a few moments before the woman sitting next to him hit her elbow into his side, “Shut up, will you?”
                <br />“What? You know it’s true; you heard the guy from the bank!”
                <br />“That’s not the point!” she shut him down quickly, turning toward Avio with an expression of pity.
                <br />Avio didn’t care too much about this man’s opinion, even if he was interested in what he was talking about, “What did the ‘guy from the bank’ say about me?”
                <br />The woman looked at Avio nervously before giving in as she looked into his eye, “He said that because there’s so many of you this time around, the militia wasn’t allowed to use any of the allotted budget on the last two people that came out of those doors. Alexander was kind enough to use his own saved-up money to make up for that, so some of the guys are pretty annoyed at you.”
                <br />Avio raised a brow, “Seriously? How petty can you be? That’s not even my fault.”
                <br />“Whose fault is it then? If you were more useful, then this wouldn’t have been an issue,” the first militia member pointed out, clearly one of the people that the woman next to him had just mentioned.
                <br />Avio looked down at the bowl of soup in front of him, “Ah...” he let out as if he had a realization.
                <br />Rayne, feeling the pressure of this conversation even though she was only sitting next to Avio, quickly asked, “Is something wrong?”
                <br />“Huh? Oh, I just realized why this tastes so bad. Our sense of smell makes up most of what we actually taste,” Avio started, before looking up and blatantly locking eyes with the man across from him, “And I can’t seem to smell anything but hot piss right now, so I guess that explains it.”
                <br />“Hah?!” the man jumped up and sent a deep glare at Avio, who simply sat there and continued eating like nothing even happened, “Hey, you better say that again, you little-“
                <br />“I said I can’t smell anything but hot piss,” Avio repeated instantly, this time not even looking at the man. Perplexed, he looked around himself before being dragged off by the woman that was sitting next to him. She was similarly glaring at Avio, but he did not care in the slightest, “That’s better.”
                <br />Rayne looked at Avio, surprise written on her face in bold letters, “Why would you say something like that?”
                <br />Avio turned toward her while soaking the bread in his hand in the soup to soften it up, “Because I didn’t want him to think that I was a pushover that would let someone, whose name I don’t even know, talk to me like that.” 
                <br />Now, it was Avio’s turn to be surprised. He figured that Rayne would find him weird and seek out another table as well, but instead, she just smiled at him, “Avio, do you want to join my party?”

                </p>
            </div>

            
        </body>
  
  );
}

export default WSA;