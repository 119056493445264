import './writingSamples.css';

function WSC() {
    return (
        <body>
            <div className="sampleBlock">
                <p  className="textBlock">
                <br /><h1>A Broken Heart</h1>
                <br />Charlie sat down on a bench at the edge of the beach, quickly pulling her backpack onto her lap. With an excited smile, she pulled out the lunch she brought from work. A thick sandwich too big to even fit into her mouth properly, with freshly baked bread and fish caught right here, not even too far out from the harbour. She enjoyed the sun on her face as she leaned back ever so slightly, feeling nearly too relaxed to even concentrate on her food.
                <br />The beach itself was rather quiet. Some people were sunbathing, a few kids were playing and building sandcastles, but overall, it was quiet. Usually, this sort of place would be overrun by tourists at this time of year, but the town was so far out of the way that most people didn’t even know there was anything down here, hidden behind the mountains. That didn’t mean that it wasn’t lively here, though. Everyone was bright and kind, not like many of the other places that Charlie had stopped by on her way here.
                <br />As she was eating, Charlie was visited by one of her newer friends, who came out here every day around this time to read the newspaper. He was never reading the local paper, though, always the paper from the closest large city. His son moved there some years ago.
                <br />“Great day today, isn’t it, William?” Charlie said with a bright smile, but William simply scoffed as he sat down next to her, his joints cracking as he did so, carefully unfolding his newspaper with his wrinkled and shaking hands, “That it is. Don’t you have anything better to do than sit here every day?” he grumbled.
                <br />“I just really like the view from here while I eat my lunch,” with a smile, Charlie placed down her food on her lap and quickly pulled a second sandwich out of her bag, “And since we’re on the topic, here you go!”
                <br />“...You really brought that with you?” William raised his brow, and Charlie nodded enthusiastically, “Yup yup, I said I would, didn’t I?”
                <br />William shook his head and focused on the news, “I’m not particularly hungry. Eat it yourself, you could use putting on some more weight. Are you sure you’re not overworking yourself there?”
                <br />“No no, it’s super relaxed there~! Now, don’t be like that! It’s got Vanessa’s cured ham,” Charlie smirked, soon seeing William glance over at her and the newspaper-wrapped sandwich she was holding. Without saying much more, he took the sandwich out of her hands and unwrapped it, slowly taking a bite that he clearly savoured with his whole body. He took a handkerchief out of his pocket and dabbed the corners of his mouth, while Charlie also continued eating her own food.
                <br />This is what they did basically every day. They came here and spent time alongside each other quietly. Sometimes, Charlie would bring some extra food for William, who always tried to act like he wasn’t as much of a foodie as he really was.
                <br />Charlie soon glanced over at William’s newspaper as he was scoffing at one of the pages again.
                <br />‘Gifted Crime out of Control’ was the largest headline. Nothing unusual, there was always a headline like that in any town known to have Gifted people. Though that didn’t stop William from commenting on it every time, “Can’t these pencil pushers finally stop picking on those poor kids?” he clicked his tongue, and Charlie simply nodded along. That was what most people said, but when it came down to it, they weren’t much better in that regard. Whenever William said things like that, Charlie couldn’t help but wonder what he would do if he found out one of those poor kids was sitting right next to him.
                <br />She wasn’t particularly keen on finding out either, though. Instead, she focused her attention elsewhere, and looked at the beach, where she saw someone else that came out here nearly every day. Though the two never actually spoke before. Charlie didn’t even know if they knew she existed.
                <br />“What are they doing over there? Doesn’t look like the usual thing,” she curiously thought out loud, and William looked up from his newspaper down at the beach, “What, Sam? Aren’t they just looking for new things to fix up again?”
                <br />“I mean... maybe?” Charlie replied, looking at Sam from afar. They had never seen them wear anything but full black, and most days they even wore this old leather jacket that simply had to be cooking them in this sort of weather, “It looks a bit different though.”
                <br />“They’re always doing whatever they want anyway, so what does it matter?” William scoffed as he straightened his newspaper, continuing to read.
                <br />“Hm, I guess you’re right...” Charlie said as she looked down at her watch. Quickly, she jumped up, “Ah, shoot, sorry! I have to run, I have to get back to work!”
                <br />“Already? Shouldn’t you have another few minutes?”
                <br />“Well... I promised to try and stop being late every time,” Charlie smiled lightly as she swung her bag over her shoulders, waving at William as she left, “See you tomorrow!”
                <br />William also waved slightly without properly looking away from his newspaper, and Charlie quickly made her way back to her place of work. She waved at the townspeople that she ran by nearly every day, smiling as she forced herself up the hill and through the maze-like town.
                <br />Before long, Charlie made it back to the Café, and patted down her skirt as she stepped through the door. There were a few customers, but it was mostly empty, and her boss was talking to one of the regulars at the counter.
                <br />“Charlie? You’re on time?” her boss, Kristie, let out with shock.
                <br />“I told you, it’s the new Charlie! I won’t be late again!”
                <br />“But weren’t you fifteen minutes late this morning?”
                <br />“That was then, and this is now!” Charlie said smugly as she stepped behind the counter, but Kristie quickly stopped her, “Actually, I’ve got something you can do to make up for always being late until now.”
                <br />Kristie grabbed the paper towel that was laying in front of the regular customer and pulled it over toward Charlie. Half-wrapped in it was a broken fountain pen. The nib was bent, and the casing cracked, and the man that brought it seemed to have a rather conflicted expression.
                <br />“I found this outside on my way here... I stepped on it, and...” the man explained, “It looks expensive though, and Kristie told me that you have a knack for finding people, so I thought maybe you could find the owner of the pen? So that I can apologize to them? I think maybe it could be fixed, but either way...”
                <br />Charlie quickly smiled as she leaned forward and took a closer look at the pen, “Well, I can definitely give it a try, but I can’t promise,” she said, turning to Kristie, “Does that mean I have the rest of the day off?”
                <br />Kristie smirked and nodded, “It’s slow right now anyway, so just go ahead. As long as you try to find the owner for the rest of the day.”
                <br />“Aye-aye, captain!” Charlie did a small salute, quickly grabbing the fountain pen as she walked toward the door, “Then I’ll get right to it! I think better on the move anyway,” she smiled, and before Kristie could take back what she said, Charlie was gone.
                <br />She continued up the hill a bit until she found an alley that people didn’t tend to look into. She stepped inside and took a closer look at the fountain pen.
                <br />“Hey there, buddy... I’m sorry that you’ve been hurt, but I’m trying to find your home. Do you think you can help me with that?” she asked, rubbing her thumb over the pen’s cracks. A small surge of warmth flowed through her fingertips, and Charlie could feel her chest be filled with feelings of love. This pen had been taken care of well. She closed her eyes, and a few images popped into her head. She felt the comforting grasp of someone holding onto her with care, guiding her over paper with deep intent. Charlie saw the vague image of an old house approaching, one that she had seen a few times before. She knew exactly where it was, and she also knew that this pen would love nothing more than to be reunited with its owner.
                <br />“Then let’s get you there. Maybe they can get you all fixed up as well,” excitedly, Charlie got moving. She ran down the hill, trying her best not to fall again, and took a few turns. She didn’t know her way around perfectly, but she had a rough idea of where she had to go at least. And before long, she stood in front of the house the pen had shown her and could feel the excitement practically flowing out of the pen’s bent nib.
                <br />With a smug grin, Charlie rang the doorbell, holding the pen in front of her so it would be the first thing seen. The door slowly opened a few moments later, before being ripped open the rest of the way as the pen was pulled out of Charlie’s hand, “Oh my god, where did you find it?”
                <br />The owner of the pen asked with a broad smile on their face. It was Sam, who Charlie had just seen at the beach earlier. Their voice was a lot clearer and more melodic than Charlie thought. They expected it to be raspier than this. Though, at least their hands were as rough as she expected.
                <br />She was a bit taken aback, and her heart was beating wildly. Knowing herself, she was sure her face was as red as a beet right now. She hadn’t been this close to them before, and they definitely never had anything close to a conversation, “I-I didn’t find it, someone at the café did... erm... They stepped on it, and-“
                <br />“Oh... right, yeah, it’s a bit broken... But that’s fine, I’ll fix it right up,” Sam smiled, carefully handling the pen to make sure it wouldn’t break any more. Without even touching it anymore, Charlie could tell how happy the pen was to be reunited with its owner.
                <br /> Charlie opened her mouth, but before any sound left her, she stopped. A dim light was coming off the cracks on the pen’s surface, and the parts were shifting together again. The nib as well was bending itself back into its original shape.
                <br />“You... you’re gifted?” Charlie asked, looking up at Sam, who awkwardly smiled, “Yeah, I guess so. I hope that doesn’t bother you.”
                <br />“No, I... It...” Not even sure what she should say, Charlie just kept looking at the pen and Sam’s hands, “I... me too...”
                <br />“Hm?”
                <br />“I also have one... a gift.”
                <br />The two looked at each other, Charlie holding onto her chest so that she could force her heart back inside should it try and mess about, and Sam with their fingers softly enveloping the pen as their cheeks took on a rosy colour as well.
                <br />Sam took a step to the side a moment later, and cleared their throat, “Erm would you... would you like to come inside for a cup of coffee?”

                </p>
            </div>

            
        </body>
  
  );
}

export default WSC;