import React from 'react';
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Link
} from 'react-router-dom';
import PortfolioNav from './components/Navbar/navbar';
import BottomBar from './components/BottomBar/bottombar';
import Home from './pages/home';
import Games from './pages/games';
import Beeware from './pages/game_subpages/beeware';
import Booknooks from './pages/game_subpages/booknooks';
import Chalkboard from './pages/game_subpages/chalkboard';
import Walking from './pages/game_subpages/walking';
import Whorigami from './pages/game_subpages/whorigami';
import Novels from './pages/novels';
import Smriag from './pages/novel_subpages/smriag';
import Dv from './pages/novel_subpages/dv';
import Starlight from './pages/novel_subpages/sn';
import WSA from './pages/novel_subpages/writingSample_A';
import WSB from './pages/novel_subpages/writingSample_B';
import WSC from './pages/novel_subpages/writingSample_C';


function App() {
  return (
    <body>
      <PortfolioNav/>
    <Router>
      <Switch>
        <Route path="/" exact element={<Home/>}/>
        <Route path="/games" exact element={<Games/>}/>
        <Route path="/games/beeware" exact element={<Beeware/>}/>
        <Route path="/games/booknooks" exact element={<Booknooks/>}/>
        <Route path="/games/chalkboard" exact element={<Chalkboard/>}/>
        <Route path="/games/walking" exact element={<Walking/>}/>
        <Route path="/games/whorigami" exact element={<Whorigami/>}/>

        <Route path="/novels" exact element={<Novels/>}/>
        <Route path="/novels/spending_my_retirement_in_a_game" exact element={<Smriag/>}/>
        <Route path="/novels/demons_virtue" exact element={<Dv/>}/>
        <Route path="/novels/starlight_necromancer" exact element={<Starlight/>}/>
        <Route path="/novels/writing_sample_a" exact element={<WSA/>}/>
        <Route path="/novels/writing_sample_b" exact element={<WSB/>}/>
        <Route path="/novels/writing_sample_c" exact element={<WSC/>}/>
        
      </Switch>
    </Router>
    <BottomBar/>
    </body>
  );
}

export default App;