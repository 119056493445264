import './novel_pages.css';
import novelSMRIAG from '../../assets/images/novelSMRIAG.png';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';


function Smriag() {
    return (
        <body>
            <div className="subnovelContent">
                <table className="mainTable">
                    <tr>
                        <td>
                            <tr><img src={novelSMRIAG} className="novelCover" /></tr>
                            <tr>
                            <Card className="statCard">
                                <Card.Body>
                                    <Card.Title> Novel Statistics </Card.Title>
                                </Card.Body>
                                <ListGroup >
                                    <ListGroup.Item className="cardList">Words - 1.56M</ListGroup.Item>
                                    <ListGroup.Item className="cardList">Views - 11.5m</ListGroup.Item>
                                    <ListGroup.Item className="cardList">Followers - 45.9k</ListGroup.Item>
                                    <ListGroup.Item className="cardList">Rating - 4.67</ListGroup.Item>
                                    <ListGroup.Item className="cardList">Start - February 2019</ListGroup.Item>

                                </ListGroup>
                            </Card>    
                            </tr>
                        </td>
                        <td>
                            <div className="novelBody">

                            <h1>Spending My Retirement In A Game</h1>
                            <h4> Description </h4>
                            <p>Follow Benjamin Joyce, an elderly man with a passion for blacksmithing who spent his life travelling 
                                the world and learning new things. Now, after helping out the developers of the first ever real VRMMO game, 
                                he gets the chance to play said game earlier than the majority of other people.
                                Taking this chance to do what he loves now that his real body is slowly giving in to his old age and the profession
                                he lives for is no longer as important as it used to be, he starts his journey to become this new world's greatest Craftsman!
                                </p>
                            <h4> My Personal Journey</h4>
                            <p>
                                When I started writing this, it was one of my first attempts at writing something long-form ever. Previously, I had created something
                                that I soon completely wiped from the internet, but I took the lessons that I had learned from it into the beginning of this novel.
                                While particularly the beginning could still use quite a few edits here and there, I am beyond proud of what I have created with this story
                                thus far.
                            </p>
                            <p>
                                It forced me out of my comfort-zone in multiple ways. Since it was a novel based on the intricacies of different crafts, I had to spend hours
                                researching and talking to people who had actual hands-on experience with the things that I was trying to write about. And even then, I still
                                got it wrong a lot. To this day, I still spend a lot of time trying to expand my knowledge of these crafts. Not only that, but I learned a lot
                                simply about the process of writing. In the beginning, I wrote multiple thousands of words a day just for this story simply because of how excited
                                I was to put more of my writing out there. This naturally forced me to expand my vocabulary, face some of my own personal biases, and of course also
                                made me engage with the criticism that my readers gave me and take it into account as I continued on.
                            </p>
                            <p>
                                I still carry all of these lessons with me, not only in my day-to-day life, but also creatively to be able to manage my passion in a productive
                                and fulfilling way.
                            </p>
                            <Button href="https://www.webnovel.com/book/spending-my-retirement-in-a-game_13225367206183305" className="novelButton"> Read Spending My Retirement In A Game </Button>
                            </div>


                        </td>
                        

                    </tr>
                    
                </table>
                
            </div>

          
        </body>
  
    );
}

export default Smriag;