

import './game_pages.css';
import Button from 'react-bootstrap/Button';
import ch_1 from '../../assets/images/chalkboard/ch_1.png';
import ch_2 from '../../assets/images/chalkboard/ch_2.png';
import ch_3 from '../../assets/images/chalkboard/ch_3.png';
import ch_4 from '../../assets/images/chalkboard/ch_4.png';


function Chalkboard() {
  return (
    <body>

        <div className="main_content">
            <div className="page_content">
                <table>
                    <tr>
                        <td className="table_main">
                            <h1> Beyond the Chalkboard </h1>
                            <p> Beyond the Chalkboard is a resource-management game based around the topics of anxiety and depression. As Sam,
                                you go through a week of stress-filled class at university, forcing you to manage your time and mental health well, all while the
                                scenarios around you adapt to your choices up until this point.
                                This game was made as a university project at the Cologne Game Lab where we were prompted to work 
                                for a client. We were asked us to create a game that can be used to help Professors acknowledge and realize the mental
                                struggles that some of their students may be going through.
                            </p>
                            <h2> Gameplay </h2>
                            <p> There are two main parts of the gameplay. For one, the player has to use their journal to plan out their week using the events or tasks
                                that can currently be found in the puzzle-like mindspace, in order to manage their stress and allow them to actually remember to fulfill
                                certain tasks. In the second part of the gameplay, the player actually follows through with this schedule, to feed their cat,
                                study for tests, or even attend certain lectures. At certain points, they will be prompted with choices that affect the player's
                                energy, stress, or confidence, the current state of which have effects on all parts of gameplay.</p>
                            <table className="table_info">
                                        <tr>
                                            <td>Position</td>
                                            <td> Gameplay + System Programmer</td>
                                        </tr>
                                        <tr>
                                            <td>Game Engine</td>
                                            <td>Unity 2021.3.7f1</td>
                                        </tr>
                                        <tr>
                                            <td>Context</td>
                                            <td>6th Semester Project @ CGL | Client Work</td>
                                        </tr>
                                        <tr>
                                            <td>Creation Period</td>
                                            <td>May 2023 - July 2023</td>
                                        </tr>
                                    </table>
                        </td> 
                    </tr>
                </table>  
                
            </div>
            <div className="page_content_2">
                <table className="content_table">
                    <tr >
                        <td>
                            <iframe className="video_embed" src="https://www.youtube.com/embed/N-I-NBMVHSY" title="Beyond the Chalkboard - Final Video Presentation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>    
                        </td>
                        <td>
                            <table>
                                <tr>
                                    <td><img src={ch_4} className="galeryImage"/></td>
                                    <td><img src={ch_2} className="galeryImage"/></td>
                                </tr>
                                <tr>
                                    <td><img src={ch_1} className="galeryImage"/></td>
                                    <td><img src={ch_3} className="galeryImage"/></td>
                                </tr>
                            </table>
                        </td>
                    </tr>                    
                </table>
            </div>
        </div>
    </body>

  );
}

export default Chalkboard;