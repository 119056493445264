import './novel_pages.css';
import novelSN from '../../assets/images/novelSN.jpg';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';


function Starlight() {
    return (
        <body>
            <div className="subnovelContent">
                <table className="mainTable">
                    <tr>
                        <td>
                            <tr><img src={novelSN} className="novelCover" /></tr>
                            <tr>
                            <Card className="statCard">
                                <Card.Body>
                                    <Card.Title> Novel Statistics </Card.Title>
                                </Card.Body>
                                <ListGroup >
                                    <ListGroup.Item className="cardList">Words - 224k</ListGroup.Item>
                                    <ListGroup.Item className="cardList">Views - 1.5M</ListGroup.Item>
                                    <ListGroup.Item className="cardList">Followers - 8.5k</ListGroup.Item>
                                    <ListGroup.Item className="cardList">Rating - 4.64</ListGroup.Item>
                                    <ListGroup.Item className="cardList">Start - June 2021</ListGroup.Item>

                                </ListGroup>
                            </Card>    
                            </tr>
                        </td>
                        <td>
                            <div className="novelBody">

                            <h1>Demon's Virtue</h1>
                            <h4> Description </h4>
                            <p>Life had never been easy for Foster. Not when he was abandoned by his family, not when he was taken in by a local crime syndicate,
                                 and also not when he suddenly found himself in another world full of magic and mystery.</p>

                            <p>The system saved his life, giving him a second chance to live however he wanted here in this new world. After being taken in
                                 by a being that should never logically exist, Foster was given the tools that he needed to achieve his selfish desires.</p>
                                
                            <h4> My Personal Journey</h4>
                            <p>
                                As my third and most recent published Webnovel, 'Starlight Necromancer' was just another passion project come to life. For a long
                                time I had been reading stories about magic and particularly necromancy myself, and soon felt the urge to try my hands at one, and soon, Foster Joyce, the
                                necromancer who uses magic and martial arts in combination was born!
                            </p>
                            <p>
                                With this story, I had the opportunity to create much deeper, better setup from the beginning. Through my previous two webnovels, I had gained the
                                experience to not only create heartfelt connections between characters, but also envelop the whole narrative in an air of mystery and intrigue.
                                So far, this story is a cumulation of my experience as a Webnovelist, and my most thoroughly planned-out and polished story so far, at least in
                                my own opinion.
                            </p>
                            <Button href="https://www.webnovel.com/book/starlight-necromancer_19750814006280805" className="novelButton"> Read Starlight Necromancer </Button>
                            </div>


                        </td>
                        

                    </tr>
                    
                </table>
                
            </div>

          
        </body>
  
    );
}

export default Starlight;