

import './home.css';
import Button from 'react-bootstrap/Button';
import poster from '../assets/images/booknooksPoster.png';
import bn_1 from '../assets/images/booknooks/bn_1.jpg';
import bn_3 from '../assets/images/booknooks/bn_3.jpg';
import ch_1 from '../assets/images/chalkboard/ch_1.png';
import ch_4 from '../assets/images/chalkboard/ch_4.png';
import bw_3 from '../assets/images/beeware/bw_3.png';
import wo_2 from '../assets/images/whorigami/wo_2.png';
import wo_3 from '../assets/images/whorigami/wo_3.png';
import wt_2 from '../assets/images/walking/wt_2.png';
import novelSMRIAG from '../assets/images/novelSMRIAG.png';
import novelDV from '../assets/images/novelDV.jpg';


function Home() {
  return (
    <body>

        <div className="main_content">
            <div className="page_content">
                <table>
                    <tr>
                        
                        <td className="table_main">
                            <h1> About me </h1>
                            <p> How's it going, my name is Dice! I'm a Game Programmer and a Webnovelist! I love creating intricate worlds that are fun for whoever ends up
                                engaging with it. Since 2019 I have been creating vast worlds in my writing, and just a year later, I began my journey in creating games as a 
                                student at the Cologne Game Lab. Ever since then, I have only expanded my knowledge and honed my craft, growing more and more passionate
                                about the work I do.
                            
                            </p>
                            <p> Down below, you can find some links leading you to examples of my work.</p>
                            {/* <table className="table_info">
                                        <tr>
                                            <td>Position</td>
                                            <td>Lead Programmer</td>
                                        </tr>
                                        <tr>
                                            <td>Game Engine</td>
                                            <td>Unreal Engine 4.25.4</td>
                                        </tr>
                                        <tr>
                                            <td>Context</td>
                                            <td>3rd Semester Project @ CGL</td>
                                        </tr>
                                        <tr>
                                            <td>Creation Period</td>
                                            <td>November 21 - February 22</td>
                                        </tr>
                                    </table> */}
                        </td> 
                    </tr>
                    
                </table>  
                
            </div>
            <div className="page_content_2">
                <table className="content_table">
                    <tr >
                        <td>
                            <table>
                                <tr>
                                    <td><img src={wo_2} className="galeryImage"/></td>
                                    <td><img src={bw_3} className="galeryImage"/></td>
                                </tr>
                                <tr>
                                    <td><img src={ch_4} className="galeryImage"/></td>
                                    <td><img src={bn_1} className="galeryImage"/></td>
                                </tr>
                                
                            </table>    
                            <Button href="/games" className="game_Button"> See my Games</Button>

                        </td>
                        <td>
                            <table>
                                <tr>
                                    <td><img src={novelSMRIAG} className="novelPosters"/></td>
                                    <td><img src={novelDV} className="novelPosters"/></td>
                                </tr>
                                
                            </table>
                            <Button href="/novels" className="novel_Button"> See my Writing </Button>

                        </td>
                    </tr>                    
                </table>
            </div>
        </div>
    </body>

  );
}

export default Home;