
import novelDV from '../assets/images/novelDV.jpg';
import novelSN from '../assets/images/novelSN.jpg';
import novelSMRIAG from '../assets/images/novelSMRIAG.png';
import './novels.css'
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function Novels(){
    return(
        <body>
            <div className="content">
                <table>
                    <tr>
                        <td className="test">
                            <tr >
                                <h2> My Writing </h2>
                                <p>Since 2019 I have been working as a Webnovelist, creating over 2.5 Million published words and accruing over 19 Million views across
                                    all my writing, as well as a combined 90 Thousand followers for my novels.</p> 
                                <p> Writing is one of my truest passions, and I try to bring in a lot of different aspects into my writing. While my focus is largely on
                                    overall Fantasy, when you look deeper, my strong-suits are creating heartfelt, emotional moments that simply pull you
                                    into the writing as well as moments of intrigue and tension that keep the reader on the edge of their seat.
                                </p>
                                <p>Since I am contractually unable to provide writing samples of my published novels on this website, I have prepared some of my other writing as
                                    samples below.
                                </p>
                            </tr>
                            <table>
                            <tr>
                                <td>
                                    <Card className="sampleCards">
                                        <Card.Body>
                                            <Card.Title> Blue Mage </Card.Title>
                                            <Card.Text>
                                                The air was stuffy, and with every single breath, Avio seemed to breathe in a fistful of dust. He instinctively held his hand in front of his mouth when he heard the floorboards creaking behind him, “When you’re done...
                                            </Card.Text>
                                            <Button href="/novels/writing_sample_a" className="cardButton">Read More</Button>
                                        </Card.Body>
                                    </Card>
                                </td>
                                <td>
                                    <Card className="sampleCards">
                                        <Card.Body>
                                            <Card.Title> The Summoner </Card.Title>
                                            <Card.Text>
                                            “It’s down here,” the elderly lady explained, the bent wooden boards of the old building creaking under the weight
                                             of her small body as she led Clementine toward the basement stairs. With a raised brow, Clementine...


                                            </Card.Text>
                                            <Button href="/novels/writing_sample_b" className="cardButton">Read More</Button>
                                        </Card.Body>
                                    </Card>
                                </td>
                                <td>
                                    <Card className="sampleCards">
                                        <Card.Body>
                                            <Card.Title> A Broken Heart </Card.Title>
                                            <Card.Text>
                                            Charlie sat down on a bench at the edge of the beach, quickly pulling her backpack onto her lap. With an excited smile, she pulled out the lunch she brought from work. A thick sandwich too big to even fit into her mouth... 
                                            </Card.Text>
                                            <Button href="/novels/writing_sample_c" className="cardButton">Read More</Button>
                                        </Card.Body>
                                    </Card>
                                </td>
                            </tr>
                            </table>
                        </td>
                        <td>
                            <Carousel className="carousel">
                                <Carousel.Item>
                                    <img src={novelSMRIAG} className="placeholder" />
                                    <Carousel.Caption className="caption">
                                        <h3>Spending my Retirement in a Game</h3>
                                        <p> An old man that spent his life honing his craft, suffering from what time has done to his body, embarks on a journey
                                            into the Virtual World.
                                        </p>
                                    <Button href="/novels/spending_my_retirement_in_a_game" className="novel_buttons"> More Info </Button>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={novelDV} className="placeholder" />
                                    <Carousel.Caption className="caption">
                                        <h3>Demon's Virtue</h3>
                                        <p> A young Imp is freed from the reigns of what is expected of him, dedicating his life to the protection of those dear to him. </p>
                                    <Button href="/novels/demons_virtue" className="novel_buttons"> More Info </Button>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={novelSN} className="placeholder" />
                                    <Carousel.Caption className="caption">
                                        <h3>Starlight Necromancer</h3>
                                        <p> A man in one of his darkest hours is transported into another world, where he is saved by an Undead creature and soon inherits
                                            its powers. </p>
                                    <Button href="/novels/starlight_necromancer" className="novel_buttons"> More Info </Button>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </td>
                    </tr>
                </table>

            </div>
        </body>
    );
}

export default Novels;