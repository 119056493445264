import './novel_pages.css';
import novelDV from '../../assets/images/novelDV.jpg';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';


function Dv() {
    return (
        <body>
            <div className="subnovelContent">
                <table className="mainTable">
                    <tr>
                        <td>
                            <tr><img src={novelDV} className="novelCover" /></tr>
                            <tr>
                            <Card className="statCard">
                                <Card.Body>
                                    <Card.Title> Novel Statistics </Card.Title>
                                </Card.Body>
                                <ListGroup >
                                    <ListGroup.Item className="cardList">Words - 1.22M</ListGroup.Item>
                                    <ListGroup.Item className="cardList">Views - 6.5M</ListGroup.Item>
                                    <ListGroup.Item className="cardList">Followers - 35.5k</ListGroup.Item>
                                    <ListGroup.Item className="cardList">Rating - 4.69</ListGroup.Item>
                                    <ListGroup.Item className="cardList">Start - November 2019</ListGroup.Item>

                                </ListGroup>
                            </Card>    
                            </tr>
                        </td>
                        <td>
                            <div className="novelBody">

                            <h1>Demon's Virtue</h1>
                            <h4> Description </h4>
                            <p>"Bleh." - Imp</p>
                            <p>
                            An Imp, a monster known to kill with a grin, a demon of the world, a creature of incredible might
                            ! Something that no force in the world can stand against! At least that's what the Main Character would like himself to be, the 
                            reality is very different, however.</p>
                            <p>
                            Exactly that Imp stumbles along a path of unfortunate fortunes and grows through it, in a world that hates its very existence.</p>
                            <p>
                            It grows more intelligent, more powerful, simply better at whatever it needs to do. Amongst other things, that includes
                             raising children, killing Soldiers, and carving wooden figurines for money.</p>
                                
                            <h4> My Personal Journey</h4>
                            <p>
                                This being my second proper novel, I had a lot more experience and knowledge about what I had to do going into this. My immediate approach
                                was a lot different already, though. While my first novel, 'Spending My Retirement In A Game', was a lot more calm and slow, this novel was
                                a lot darker right from the very beginning, though that didn't stop me from trying to make it lighthearted at times. 
                            </p>
                            <p>
                                I went into this with more of an idea of where I wanted the story to go, and how grand I wanted it to be. From the beginning I set up a lot of things
                                that are still paying off to this day or have only been explored partially, but will be fully wrapped-up by the end of the novel. Since the setting and 
                                themes were already a lot more serious from the get-go, I had the chance to dive into a lot more serious topics that didn't fit my other novel at the time.
                                I took the opportunity to set up long-time mysteries, explored unique character-relationships and the tension that comes with them, and systematically approached
                                the growth of particularly the Main Character both in terms of strength within the world of the novel, as well as Personality-wise. In the beginning, the main 
                                character is a true monster that lives off of instincts, but soon becomes a loving foster parent and intelligently thinks about his plans.
                            </p>
                            <Button href="https://www.webnovel.com/book/demon's-virtue_15238973305579305" className="novelButton"> Read Demon's Virtue </Button>
                            </div>


                        </td>
                        

                    </tr>
                    
                </table>
                
            </div>

          
        </body>
  
    );
}

export default Dv;