import './writingSamples.css';

function WSB() {
    return (
        <body>
            <div className="sampleBlock">
                <p  className="textBlock">
                <br /><h1>The Summoner</h1>
                <br />“It’s down here,” the elderly lady explained, the bent wooden boards of the old building creaking under the weight of her small body as she led Clementine toward the basement stairs. With a raised brow, Clementine leaned forward, the dusty air that made up the lower level already attacking her nostrils.
                <br />“Do you regularly rent out the basement rooms?” Clementine asked, tapping the cork of the small glass tube attached to her hip.
                <br />“Normally only as storage... but he insisted on it! Offered me nearly as much money as for a regular room, so why would I turn it down? Should’ve known there was something off about him...”
                <br />“I see...” as the stone inside of the glass tube started glowing in a dim blue light, enough to illuminate the dark basement, Clementine started walking down the uneven stairs. The ceiling here was so low that even this old woman would have to duck, but the hallway itself was high enough to stand properly. The elderly woman guided Clementine and the two uniformed men following behind her to the room at the end of the basement.
                <br />“Here it is! Get him out of here!” The owner of the house explained, and Clementine slowly approached the door. She looked down at the lock and frowned, “You said your keys don’t work anymore?”
                <br />“No, I don’t know what that man did, but he must have broken the door somehow!”
                <br />The moment she got down on her knee to take a closer look, Clementine noticed that the owner wasn’t all too far off. The lock was indeed broken; it had been molten down and deformed completely, so it was neither possible to unlock it nor even look through the keyhole. However, the hinges of the door were rusted over quite a lot.
                <br />Clementine stood back up and looked at the two men behind her, “You two, break the hinges and open the door that way.”
                <br />“Yes, Ma’am!” one of the two replied, as the men quickly got to work. The elderly woman looked at them curiously and then turned back toward Clementine, “Will you arrest him? Throw him in jail?”
                <br />Clementine looked to her side and smiled lightly, “Well, I can’t really say that yet. If he’s aggressive when we remove him from the room, then we might have to. Either way, we’re going to take him with us for now to make sure he’s away from your property. Then we’ll figure out a way to get you your money back as well.”
                <br />“Oh, thank you! That would be quite wonderful!” the elderly woman exclaimed as she clapped her hands together, while Clementine suppressed a sigh. She couldn’t believe that she had to be here and do things like this. If she had kept her mouth shut in front of her superior, then she would at least be allowed to do her job properly. And while she hadn’t been demoted directly, she was basically just babysitting the new Uniforms. At this rate, there was nothing she could do to earn back the trust she lost.
                <br />As she finished her thought, the two Uniforms managed to pull the door off, and before Clementine could even say anything, she was assaulted by a rancid, unmistakably foul smell.
                <br />“...Jameson, head back to the station and call for assistance,” Clementine said without hesitation, and the man, realizing what that horrendous smell was as well, nodded his head and rushed up the stairs, while the other Uniform quickly brought the owner of the house upstairs so that Clementine could get to work.
                <br />She stepped into the windowless room; her feet cushioned by a thick layer of dust on the floor. There were towers of books lined up against the walls, and against the opposite wall to the door stood a desk. A small lamp illuminated the desk and nothing else, casting the shadow of the man sitting at it toward Clementine.
                <br />“Sir?” She said, already knowing she wouldn’t get a response. She continued toward the desk, soon peaking over the body. But instead, she was distracted by the thick, sticky liquid she stepped into. Her first instinct was blood, but she soon realised that it wasn’t anything of the sort.
                <br />Clementine looked over at the body, holding the glowing glass tube in its direction. A black substance was leaking out the body’s eyes, nose, ears and mouth. Confused, she glanced at the open book on the table. One of the pages was completely empty, while the other’s letters were smudged and shifted. Instinctively, Clementine’s eyes moved toward the body’s hands, which were completely coated in the same substances as was on the floor, parts of it still dripping down into the puddle.
                <br />With a click of her tongue, Clementine rubbed the bridge of her nose, “A fucking Sorcerer, of course.”
                <br />“Now, now, that’s not a nice thing to say, is it?” A voice chuckled from the doorway. A voice that made the hairs on Clementine’s neck stand up.
                <br />“Who the hell are you?” Clementine barked out, and the man took a few more steps inside, glancing around the room. He soon stopped, seeing the woman in front of him be so on edge, and stopped. He pressed his feet together and snapped his finger, and a half-dozen candle-sized blue flames appeared around his hand. They soon spread around the room, and Clementine placed her hand onto her hip, ready to defend herself if necessary.
                <br />The flames quickly lit up the room and Clementine got a good view of the man. He was tall, incredibly so, his head nearly scratching the ceiling of the room. He was wearing a dark suit that could very well be more expensive than this whole house, and a long coat and leather gloves to match. The man bowed forward, holding one of his hands against his chest.
                <br />“The name is Malachite G. Brier. It is a pleasure to make your acquaintance, Detective Rozier,” the man smiled almost creepily. Clementine was taken aback; how did he know her name?
                <br />Though, just a few moments later, the tension was cut by the entrance of a young man, no more than fourteen years old, that rushed into the room behind that man, “Could you stop running off like that all the time, Malachite?” he asked, clearly out of breath from running. He was carrying a large bag on his back that was clearly far too big for him. As soon as the boy stopped to figure out what was going on, he held his nose, “Another body? How do you always find those things?”
                <br />The man, ‘Malachite’, laughed in a low voice, “You know I have my-“
                <br />“Wait, when did you grab the sulphur for the fairy lights?” The boy interrupted, looking down at the pouch hanging off his chest, double-checking to make sure it was closed, “I told you not to just go through my things like that!”
                <br />“Well, they’re ‘my’ materials though, so why should I not be able to grab them whenever I want?”
                <br />“That’s not the point, you-“ Clementine clapped her hands together to get the attention of the two strangers. Though, after the boy came in, she realised that they maybe weren’t total strangers after all.
                <br />“Malachite Brier? You’re that Sorcerer working with us, right? How did you know there was a body here?”
                <br />“Hm? Oh, as I was about to say earlier, I have my ways. But that’s not what’s important here, is it?” Malachite stepped toward the dead body, but Clementine quickly blocked his way, “Stop that. You can go, it’s as clear as day anyway. Just another Sorcerer that overestimated their abilities and ended up killing themself. It’s a clear case of a magical overdose. Textbook, really.”
                <br />Curiously, Malachite glanced over Clementine’s head to take a closer look at the body. He then looked around the room, locking his eyes on a few areas for a couple of seconds before moving on. Then, he let out a loud laugh, “A textbook overdose, you say? Don’t make me laugh. There’s foul play at hand here.”
                <br />Clementine frowned, “Foul play? What makes you say that?”
                <br />“Well, it’s easy as day, really. This man is a Knowledge-Sorcerer of kind. Locked himself down here to absorb the information in the books, and that did certainly lead to an overdose, which is the ink you see pouring out of his hands and... orifices. But it wasn’t deadly quite yet. A true death by overdose is a painful matter. No man, no matter how strong of heart, would be able to hold out without struggling,” Malachite pointed out, before looking toward the boy behind him and simply giving him a nod. The boy sighed and put his bag down to quickly rummage through it.
                <br />Clementine looked around the room. There were no ink-splatters anywhere, only the desk had some smudges here and there, “Maybe he was different? The pain could have petrified him.”
                <br />“That is one possibility. But it’s more likely someone else was involved. Sorcerers of his king are logical. Methodical. His clothes are well-kept, everything on the desk is perfectly laid out. And he clearly read these books in a particular order. The books with mathematics and general sciences have been read, but the social sciences and biographies hadn’t been touched yet. But as out of order as they are, that leads me to believe-“
                <br />“That someone else was here and looked for something, and didn’t bother to...”
                <br />Malachite smiled, quickly receiving a few different things from the boy. He started rubbing them between his palms and fingertips, “Exactly that, Detective.”
                <br />“I see. Then let’s go and wait for the forensics team outside.”
                <br />“Forensics team? We don’t need to wait for those hacks,” Malachite scoffed, as he threw the things in his hands into the air. They smelled faintly of flowers, and slightly metallic as well, seemingly a mixture of different things that had been ground into a rough powder. Though Clementine could swear they were whole just a few moments ago.
                <br />Malachite snapped his fingers, and the powder lit up into flames, though just for a moment. And soon, in the flames’ stead, something floated in the air. Malachite slightly nodded his head, “Do you think you could help me out here?” he asked, and the creature floating in the air replied in a voice too quiet for Clementine to understand.
                <br />But soon, it flapped its wings and moved around, giving Clementine a better chance to see it. It was a small Pixie, with glass-like butterfly wings on its back. The room was filled with light once more, but not from the flames created by Malachite earlier. Instead, glowing mist filled the room, in three distinct colours.
                <br />Malachite was enveloped in a clear blue mist, and the body had two different colours rising off it. A deep green, which was the most prominent, but some thin strands of orange were mixed into it. And that same colour also seemed to stick to the door’s molten lock.
                <br />“I see. Alright, let’s go,” Malachite said as the butterfly-winged Pixie sat down on his shoulder. Clementine was still confused and grabbed the man’s arm, “Wait, where are you going?”
                <br />Malachite smiled and looked at Clementine, thinking that the answer was obvious, “Where am I going? To catch your murderer, of course.” 

                </p>
            </div>

            
        </body>
  
  );
}

export default WSB;